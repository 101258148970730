import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import {
  ListAllLocationsDataCommandResult,
  ListBedsCommandViewModel,
  ListBuildingsCommandViewModel,
  LocationService,
  LocationViewModel,
  RoomViewModel
} from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss']
})
export class LocationSelectorComponent implements OnInit, OnChanges, OnDestroy {
  form: FormGroup;
  originalFormState: FormGroup;
  public allLocationsDataCommandResult: ListAllLocationsDataCommandResult = null;
  public classWrapper = 'col-xs-12 col-md-6';
  public loading = true;

  @Input() allowBedValue: boolean = true;
  @Input() initialValues: AbstractControl;
  @Input() events: Observable<AbstractControl>;
  @Output() onFormGroupChange = new EventEmitter<FormGroup>();
  private eventsSubscription: Subscription;

  constructor(private logger: NGXLogger, private fb: FormBuilder, private locationService: LocationService) {
    this.form = this.fb.group(
      {
        locationId: [this.defaultItemLocation.id, []],
        buildingId: [this.defaultItemBuilding.id, []],
        roomId: [this.defaultItemRoom.id, []],
        bedId: [this.defaultItemBed.id, []]
      },
      {
        updateOn: 'blur'
      }
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['initialValues']) {
      this.initialValues = changes['initialValues'].currentValue;
    }
  }

  public ngOnInit() {
    this.loading = true;
    this.EmitFormValue();

    this.eventsSubscription = this.events.subscribe((formGroup: AbstractControl) => {
      if (!formGroup) return;

      this.logger.log('Event called on child', formGroup.value, this.allLocationsDataCommandResult);

      let allLocationsDataCommandResult = this.locationService.allLocationData();

      allLocationsDataCommandResult.subscribe((data: ListAllLocationsDataCommandResult) => {
        this.loading = false;
        this.allLocationsDataCommandResult = data;

        let selectedLocation = this.allLocationsDataCommandResult.data.find(x => x.id == formGroup.value.locationId) as LocationViewModel;
        let selectedBuilding = null;
        let selectedRoom = null;
        let selectedBed = null;

        if (selectedLocation) {
          selectedBuilding = selectedLocation.buildings.find(x => x.id == formGroup.value.buildingId);
        }
        if (selectedBuilding) {
          selectedRoom = selectedBuilding.rooms.find(x => x.id == formGroup.value.roomId);
        }
        if (selectedRoom) {
          selectedBed = selectedRoom.bedViewModels.find(x => x.id == formGroup.value.bedId);
        }

        if (selectedLocation) {
          this.handleLocationChange(selectedLocation);
          if (selectedBuilding) {
            this.handleBuildingChange(selectedBuilding);
            if (selectedRoom) {
              this.handleRoomChange(selectedRoom);
              if (selectedBed) {
                this.handleBedChange(selectedBed);
              }
            }
          }
        }
      });
    });
  }

  public ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  private EmitFormValue() {
    this.onFormGroupChange.emit(this.form);
  }

  public dataResultBuildings: Array<ListBuildingsCommandViewModel> = [];
  public dataResultRooms: Array<RoomViewModel> = [];
  public dataResultBeds: Array<ListBedsCommandViewModel> = [];

  public selectedLocation: LocationViewModel;
  public selectedBuilding: ListBuildingsCommandViewModel;
  public selectedRoom: RoomViewModel;
  public selectedBed: ListBedsCommandViewModel;

  public defaultItemLocation: LocationViewModel = {
    name: 'Select a Location',
    id: null
  };

  public defaultItemBuilding: ListBuildingsCommandViewModel = {
    name: 'Select a Building',
    id: null
  };

  public defaultItemRoom: RoomViewModel = {
    name: 'Select a Room',
    id: null
  };

  public defaultItemBed: ListBedsCommandViewModel = {
    customId: 'Select a Bed',
    id: null
  };

  public isDisabledBuildings = true;
  public isDisabledRooms = true;
  public isDisabledBeds = true;

  public handleLocationChange(value: LocationViewModel) {
    this.selectedLocation = value;
    this.form.setValue({
      locationId: this.selectedLocation.id,
      buildingId: this.defaultItemBuilding.id,
      roomId: this.defaultItemRoom.id,
      bedId: this.defaultItemBed.id
    });
    this.EmitFormValue();

    this.selectedBuilding = undefined;
    this.selectedRoom = undefined;
    this.selectedBed = undefined;

    if (value.id === this.defaultItemLocation.id) {
      this.isDisabledBuildings = true;
      this.dataResultBuildings = [];
    } else {
      this.isDisabledBuildings = false;
      this.dataResultBuildings = this.selectedLocation.buildings;
    }

    this.isDisabledRooms = true;
    this.isDisabledBeds = true;
    this.dataResultRooms = [];
    this.dataResultBeds = [];
  }

  public handleBuildingChange(value: ListBuildingsCommandViewModel) {
    this.selectedBuilding = value;
    this.form.setValue({
      locationId: this.selectedLocation.id,
      buildingId: this.selectedBuilding.id,
      roomId: this.defaultItemRoom.id,
      bedId: this.defaultItemBed.id
    });
    this.EmitFormValue();

    this.selectedRoom = undefined;
    this.selectedBed = undefined;

    if (value.id === this.defaultItemBuilding.id) {
      this.isDisabledRooms = true;
      this.dataResultRooms = [];
    } else {
      this.isDisabledRooms = false;
      this.dataResultRooms = this.selectedBuilding.rooms;
    }

    this.isDisabledBeds = true;
    this.dataResultBeds = [];
  }

  public handleRoomChange(value: RoomViewModel) {
    this.selectedRoom = value;
    this.form.setValue({
      locationId: this.selectedLocation.id,
      buildingId: this.selectedBuilding.id,
      roomId: this.selectedRoom.id,
      bedId: this.defaultItemBed.id
    });
    this.EmitFormValue();

    this.selectedBed = undefined;

    if (value.id === this.defaultItemRoom.id) {
      this.isDisabledBeds = true;
      this.dataResultBeds = [];
    } else {
      this.isDisabledBeds = false;
      this.dataResultBeds = this.selectedRoom.bedViewModels;
    }
  }

  public handleBedChange(value: ListBedsCommandViewModel) {
    this.selectedBed = value;
    this.form.setValue({
      locationId: this.selectedLocation.id,
      buildingId: this.selectedBuilding.id,
      roomId: this.selectedRoom.id,
      bedId: this.selectedBed.id
    });
    this.EmitFormValue();
  }
}
